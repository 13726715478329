import * as React from "react"
import Layout from "../components/Layout";
import styled from "styled-components";
import Wrapper from "../components/Wrapper";
import { graphql } from 'gatsby'
import CasePreview from "../components/CasePreview";
import { getImage } from "gatsby-plugin-image";

const CasesWrapper = styled.div`
    display:flex;
    margin:30px 0;
    flex-wrap:wrap;
`

const Cases = ({ data }) => {
    return (
        <Layout>
            <Wrapper>
                <h1 className="mt-20 text-center text-6xl font-bold">Cases</h1>
                <CasesWrapper>
                {data.allDatoCmsWork.edges.map(({node: work }) => (
                    <CasePreview
                        key={work.id}
                        title={work.title}
                        tag={work.tag}
                        slug={work.slug}
                        excerpt={work.excerpt}
                        image={getImage(work.featuredImage[0])}
                        />
                    ))}
                </CasesWrapper>
            </Wrapper>
        </Layout>
    )
}

export default Cases

export const query = graphql`
  query CasesQuery {
    allDatoCmsWork(sort: {fields: position, order: ASC}){
      edges {
        node {
          ...caseFields
        }
      }
    }
  }
`